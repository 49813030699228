<template>
  <div class="wrapper">
    <div class="body" style="padding: 0">
      <Slider></Slider>

      <div class="cas-header-wrapper">
        <div class="se-wrapper py-2 px-2">
          <div class="d-flex justify-content-around">
            <div>
              <select
                class="form-control mb-1"
                v-if="providers.length > 0"
                v-model="providerID"
                @change="handleProviderChange"
              >
                <option disabled selected value="">Select Provider ID</option>
                <option
                  v-for="provider in providers"
                  :key="provider.id"
                  :value="provider ? provider.id : ''"
                >
                  {{ provider ? provider.id : "n/a" }}
                  {{ provider.name }}
                </option>
              </select>

              <small v-else class="text-danger"
                >No providers available. Please try again later.</small
              >
            </div>

            <!-- <div>
              <button
                class="btn btn-sm btn-success"
                @click="getCasinoGames(casinoCategoryActive)"
                :disabled="!providerID"
              >
                Get Provider
              </button>
            </div> -->
          </div>

          <div class="input-group mb-0">
            <input
              @keyup="keyUpSearch"
              @keyup.enter="performSearch"
              type="text"
              class="form-control"
              id="search-input"
              name="keyword"
              placeholder="Search for casino games"
              aria-label="Search for games, leagues, events"
              v-model="to_search"
              style="background-color: white; color: black; font-size: unset"
            />
            <div
              class="input-group-append cursor-pointer"
              @click="performSearch"
            >
              <span
                class="input-group-text text-light"
                style="background-color: var(--lighestgray1); font-size: unset"
                ><i class="bi bi-search mr-1"></i>
                Search
              </span>
            </div>
          </div>
        </div>

        <div class="catsec pb-3 pt-1" id="catsec">
          <span
            v-for="(item, index) in categories"
            class="cattit"
            @click="getCasinoGames(item)"
            :class="isActive(item)"
            v-bind:key="getKey(index)"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
      <ShimmerCasino v-if="loading"></ShimmerCasino>

      <div class="row m-0 p-0" style="background-color: #1c2243">
        <div
          v-show="searchgamesdata.length > 0"
          class="casino-headers"
          style=""
        >
          <span>Search Results</span>
        </div>

        <!-- new card design -->
        <div
          v-show="searchgamesdata.length > 0"
          class="row m-0 p-0 casino-row"
          v-for="(o, index) in searchgamesdata"
          v-bind:key="getKey(index)"
        >
          <div
            class="game-icon casino-card col-lg-3 col-md-3 col-sm-3 col-3 m-0 p-0"
          >
            <a>
              <img
                loading="lazy"
                :src="getImg(o.image)"
                alt="Avatar"
                class="game-card"
                data-toggle="modal"
                style="border: solid var(--light-gray) 1px"
                :data-target="'#searchCasinoGames' + o.game_id"
              />
            </a>
          </div>
          <div
            class="modal fade"
            :id="'searchCasinoGames' + o.game_id"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'searchCasinoGamesLabel' + o.game_id"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog" role="document">
              <div
                style="color: var(--buttonBackgroundColor)"
                class="modal-content"
              >
                <div class="modal-header">
                  <h5
                    class="modal-title"
                    :id="'searchCasinoGamesLabel' + o.game_id"
                  >
                    {{ o.game_name }}
                  </h5>
                  <span
                    style="color: var(--buttonBackgroundColor)"
                    type="button"
                    class="close"
                    id="faso-close"
                    data-dismiss="modal"
                    >&times;</span
                  >
                </div>
                <div class="modal-body">
                  <img
                    v-if="o.image != 'Jet Safi'"
                    loading="lazy"
                    style="
                      border-radius: 5px;
                      border: solid var(--light-gray) 1px;
                    "
                    :src="getImg(o.image)"
                    alt=""
                    class="ww-100 mb-2"
                  />
                  <div class="text-center">
                    <router-link
                      data-dismiss="modal"
                      style="
                        background-color: var(--buttonBackgroundColor);
                        color: var(--darkBackground);
                      "
                      class="btn ww-100 mb-2"
                      :to="{
                        name: 'casinogames',
                        params: {
                          IsDemo: 0,
                          providerID: o.provider_id,
                          gameName: o.game_name,
                          gameID: o.game_id,
                          launchURL: o.launch_url,
                          launchType: o.launch_type,
                          launchParameters: o.launch_parameters,
                        },
                      }"
                    >
                      Play Now
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- new card design -->
      </div>

      <div id="scroller">
        <div
          v-show="searchgamesdata.length == 0"
          class="row m-0 p-0 pb-5"
          style="background-color: var(--gray-white)"
        >
          <!-- homepopular games -->
          <!-- <div style="text-align: center;" v-for="(i, x) in homePagePopularGames" v-bind:key="getKey(x)"
            class="casino-game col-lg-3 col-md-3 col-sm-3 col-4 m-0 p-1">
            <div class="game-icon">
              <img loading="lazy" :src="getImg(i.image)" alt="Avatar" data-toggle="modal"
                :data-target="'#casinoGames' + i.gameID"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--light-gray) 1px;" />
            </div>

            <div class="modal fade" :id="'casinoGames' + i.gameID" tabindex="-1" role="dialog"
              :aria-labelledby="'casinoGamesLabel' + i.gameID" aria-hidden="true">
              <div class="modal-dialog modal-dialog" role="document">
                <div style="color: var(--buttonBackgroundColor);" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" :id="'casinoGamesLabel' + i.gameID">{{ i.gameName }}</h5>
                    <span style="color: var(--buttonBackgroundColor);" type="button" class="close" id="faso-close"
                      data-dismiss="modal">&times;</span>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" style="border-radius: 5px;border: solid var(--light-gray) 1px;" :src="i.image"
                      alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal"
                        style="background-color: var(--buttonBackgroundColor);color: var(--darkBackground);"
                        class="btn ww-100 mb-2"
                        :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: i.providerID, gameName: i.gameName, gameID: i.gameID, launchURL: i.launch_url, launchType: i.launch_type, launchParameters: i.launch_parameters }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div
            style="text-align: center"
            v-for="(i, x) in homePagePopularGames"
            v-bind:key="getKey(x)"
            class="casino-game col-lg-3 col-md-3 col-sm-3 col-4 m-0 p-1"
          >
            <div class="game-icon">
              <img
                loading="lazy"
                :src="getImg(i.image)"
                alt="Avatar"
                data-toggle="modal"
                :data-target="'#casinoGames' + i.game_id"
                style="
                  max-width: 100%;
                  object-fit: contain;
                  border-radius: 16px;
                  padding: 0;
                  background-color: var(--lightest-gray);
                  border: solid var(--light-gray) 1px;
                "
              />
            </div>

            <div
              class="modal fade"
              :id="'casinoGames' + i.game_id"
              tabindex="-1"
              role="dialog"
              :aria-labelledby="'casinoGamesLabel' + i.game_id"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog" role="document">
                <div
                  style="color: var(--buttonBackgroundColor)"
                  class="modal-content"
                >
                  <div class="modal-header">
                    <h5
                      class="modal-title"
                      :id="'casinoGamesLabel' + i.game_id"
                    >
                      {{ i.game_name }}
                    </h5>
                    <span
                      style="color: var(--buttonBackgroundColor)"
                      type="button"
                      class="close"
                      id="faso-close"
                      data-dismiss="modal"
                      >&times;</span
                    >
                  </div>
                  <div class="modal-body">
                    <img
                      loading="lazy"
                      style="
                        border-radius: 5px;
                        border: solid var(--light-gray) 1px;
                      "
                      :src="i.image"
                      alt="gameimg"
                      class="ww-100 mb-2"
                    />
                    <div class="text-center">
                      <router-link
                        data-dismiss="modal"
                        style="
                          background-color: var(--buttonBackgroundColor);
                          color: var(--darkBackground);
                        "
                        class="btn ww-100 mb-2"
                        :to="{
                          name: 'casinogames',
                          params: {
                            IsDemo: 0,
                            providerID: i.provider_id,
                            gameName: i.game_name,
                            gameID: i.game_id,
                            launchURL: i.launch_url,
                            launchType: i.launch_type,
                            launchParameters: i.launch_parameters,
                          },
                        }"
                      >
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="text-align: center"
            v-for="(i, x) in allgamesdata"
            v-bind:key="getKey(x)"
            class="casino-game col-lg-3 col-md-3 col-sm-3 col-4 m-0 p-1"
          >
            <div class="game-icon">
              <img
                v-if="i.game_name != 'Jet Safi'"
                loading="lazy"
                :src="getImg(i.image)"
                alt="Avatar"
                data-toggle="modal"
                :data-target="'#casinoGames' + i.game_id"
                style="
                  max-width: 100%;
                  object-fit: contain;
                  border-radius: 16px;
                  padding: 0;
                  background-color: var(--lightest-gray);
                  border: solid var(--light-gray) 1px;
                "
              />
              <img
                v-else
                loading="lazy"
                src="/img/Jetsafinew.png"
                alt="Avatar"
                data-toggle="modal"
                :data-target="'#casinoGames' + i.game_id"
                style="
                  max-width: 100%;
                  object-fit: contain;
                  border-radius: 16px;
                  padding: 0;
                  background-color: var(--lightest-gray);
                  border: solid var(--light-gray) 1px;
                "
              />
            </div>

            <div
              class="modal fade"
              :id="'casinoGames' + i.game_id"
              tabindex="-1"
              role="dialog"
              :aria-labelledby="'casinoGamesLabel' + i.game_id"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog" role="document">
                <div
                  style="color: var(--buttonBackgroundColor)"
                  class="modal-content"
                >
                  <div class="modal-header">
                    <h5
                      class="modal-title"
                      :id="'casinoGamesLabel' + i.game_id"
                    >
                      {{ i.game_name }}
                    </h5>
                    <span
                      style="color: var(--buttonBackgroundColor)"
                      type="button"
                      class="close"
                      id="faso-close"
                      data-dismiss="modal"
                      >&times;</span
                    >
                  </div>
                  <div class="modal-body">
                    <img
                      v-if="i.game_name != 'Jet Safi'"
                      loading="lazy"
                      style="
                        border-radius: 5px;
                        border: solid var(--light-gray) 1px;
                      "
                      :src="i.image"
                      alt="gameimg"
                      class="ww-100 mb-2"
                    />
                    <img
                      v-else
                      loading="lazy"
                      style="
                        border-radius: 5px;
                        border: solid var(--light-gray) 1px;
                      "
                      src="/img/Jetsafinew.png"
                      alt="gameimg"
                      class="ww-100 mb-2"
                    />
                    <div class="text-center">
                      <router-link
                        data-dismiss="modal"
                        style="
                          background-color: var(--buttonBackgroundColor);
                          color: var(--darkBackground);
                        "
                        class="btn ww-100 mb-2"
                        :to="{
                          name: 'casinogames',
                          params: {
                            IsDemo: 0,
                            providerID: i.provider_id,
                            gameName: i.game_name,
                            gameID: i.game_id,
                            launchURL: i.launch_url,
                            launchType: i.launch_type,
                            launchParameters: i.launch_parameters,
                          },
                        }"
                      >
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.modal-content {
  background-color: #1c2243;
}

.modal-body {
  background-color: #1c2243;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.category-tabs {
  max-width: calc(100% - 80px);
  /* Adjust the maximum width to leave space for the button */
  overflow-x: auto;
  /* Enable horizontal scrolling */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.pagination-btn {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-num {
  color: white;
  font-weight: 600;
  padding: 3px;
}

.nav-link {
  color: white;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.nav-link {
  background-color: #033e1f;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  margin-left: 2px;
}

.nav-tabs .nav-link.active {
  color: green !important;
  font-weight: 600;
  background-color: #f9b233;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.hover {
  color: green !important;
  font-weight: 600;
  background-color: grey;
  border-color: #dee2e6 #dee2e6 #fff;
}

.carousel-container {
  overflow-x: auto;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel-arrow-left {
  left: 0;
}

.carousel-arrow-right {
  right: 0;
}

a:active {
  color: green;
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Style for leaderboard section */
.leaderboard-container {
  margin-top: 20px;
}

/* Style for leaderboard list */
.leaderboard-list {
  list-style-type: none;
  padding: 0;
}

/* Style for leaderboard list item */
.leaderboard-item {
  margin-bottom: 5px;
}

/* Style for MSISDN, name, and bets */
.msisdn {
  font-weight: bold;
}

.name {
  margin-left: 5px;
}

.bets {
  margin-left: 5px;
}

/* Responsive styling */
@media only screen and (max-width: 768px) {
  /* Adjust font size for smaller screens */
  .msisdn,
  .name,
  .bets {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  /* Adjust margins for smaller screens */
  .name,
  .bets {
    margin-left: 0;
    margin-top: 5px;
    /* Add space between items */
  }
}

@media (min-width: 768px) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 4 games per row with 20px margin between them */
  }
}

@media (max-width: 767.98px) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 3 games per row with 20px margin between them */
  }
}

.game-card {
  position: relative;
}

.game-icon img {
  width: 100%;
  height: auto;
  /* Ensure image maintains aspect ratio */
  border-top-left-radius: 5px;
  /* Adjust border radius to match card */
  border-top-right-radius: 5px;
  /* Adjust border radius to match card */
}

.game-details {
  width: 100%;
}

.game-details p {
  margin-bottom: 5px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favorite-icon {
  position: absolute;
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  font-size: 20px;
  /* Adjust font size as needed */
  color: red;
  /* Adjust color as needed */
  cursor: pointer;
}

.play-button {
  width: 100%;
  /* Fill the width */
  padding: 10px;
  /* Add padding as needed */
  background-color: #f9b233;
  /* Add desired background color */
  color: #033e1f;
  /* Add desired text color */
  font-weight: 600;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.game-card:hover {
  transform: translateY(-5px);
}

.filter-dropdown select {
  appearance: none;
  /* Remove default appearance */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ccc;
  /* Border */
  border-radius: 5px;
  /* Border radius */
  padding: 8px 12px;
  /* Padding */
  font-size: 11px;
  /* Font size */
  font-weight: 600;
  /* Font weight */
  cursor: pointer;
  /* Cursor on hover */
}

/* Dropdown arrow */
.filter-dropdown select::after {
  content: "\25BC";
  /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevents the arrow from being clickable */
}

/* Hover effect */
.filter-dropdown select:hover {
  border-color: #aaa;
  /* Border color on hover */
}

/* Focus effect */
.filter-dropdown select:focus {
  outline: none;
  /* Remove outline */
  border-color: #555;
  /* Border color on focus */
}

.filter-dropdown select option {
  background-color: #fff;
  /* Background color */
  color: #333;
  /* Text color */
  font-size: 11px;
  /* Font size */
}

.filter-dropdown select:disabled {
  opacity: 0.5;
  /* Reduced opacity */
  cursor: not-allowed;
  /* Disabled cursor */
}

.search-bar {
  position: relative;
}

.search-bar input[type="text"] {
  width: calc(100% - 30px);
  /* Subtracting the total padding (15px on each side) */
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  outline: none;
}

.search-bar input[type="text"]:focus {
  border-color: #007bff;
}

.search-bar .search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
}

.search-bar .search-icon:hover {
  color: #007bff;
}

.custom-input {
  width: 100%;
  /* Adjusted width to match the search icon */
  padding: 6px 12px;
  /* Adjusted padding to match the filter button */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  outline: none;
}

.custom-input:focus {
  border-color: #007bff;
}

.custom-input::placeholder {
  color: #666;
  /* Placeholder text color */
  font-style: italic;
  /* Italicize placeholder text */
}

.BottomFooter {
  margin-top: 20px;
  /* Adjust this value as needed */
}

.vue-carousel-slide {
  padding: 10px;
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .vue-carousel-slide {
    padding: 0 10px;
    /* Adjust padding for smaller screens */
  }
}

/* Button styling */
.nav-link {
  display: block;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: white !important;
  cursor: pointer;
}

.nav-link.active {
  background-color: #f9b233;
  font-weight: 600;
  color: white !important;
}

.nav-link:hover {
  background-color: #fff;
  font-weight: 600;
  color: green !important;
}

.tab-navigation {
  display: flex;
  justify-content: space-around;
}

.tab-navigation button {
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

.tab-navigation button.active {
  background-color: green;
  color: white;
}

.scrollable-nav {
  overflow-x: auto;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
}

.nav-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.nav-item {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #f9f9f9;
  transition: background-color 0.3s, color 0.3s;
}

.nav-item:hover {
  background-color: #007bff;
  color: #fff;
}

.nav-link {
  text-decoration: none;
  color: #333;
}

.nav-item.active {
  background-color: #007bff;
  color: #fff;
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .game-details {
    text-align: center;
    /* Center align the game details */
  }
}
</style>
<script>
import Vue from "vue";
import ShimmerCasino from "../shimmer/ShimmerCasino.vue";
import ScrollLoader from "vue-scroll-loader";
import casino from "@/services/casino";

const Slider = () => import("../SliderCasino.vue");

Vue.use(ScrollLoader);
export default {
  name: "Casino",
  components: {
    Slider,
    ShimmerCasino,
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      to_search: "",
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          id: 9,
          title: "Popular",
        },
        {
          id: 4,
          title: "Slots",
        },
        // {
        //   "id" : 16,
        //   "title" : "Video Slots"
        // },
        // {
        //   "id" : 12,
        //   "title" : "Jackpot Games"
        // },
        {
          id: 17,
          title: "Classic Casino",
        },
        {
          id: 8,
          title: "Table Games",
        },
        // {
        //   "id" : 7,
        //   "title" : "Poker"
        // },
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        id: 9,
        name: "Crash",
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      currentPage: 1,
      perPage: 600,
      // selectedprovider:'',
      providerID: "",
      providers: [],
      homePagePopularGames: [],
    };
  },

  mounted: function () {
    this.$store.dispatch("setCurrentPage", "casino");

    // this.getCasinoGames(this.casinoCategoryActive);

    this.reloadProfile();

    var categories = [];
    // var popular =[];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {
        if (x.key == key) {
          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {
        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.getCategories();
    // this.getPopular();

    this.showSlides();
    var touchableElement = document.getElementById("scroller");
    touchableElement.addEventListener(
      "touchstart",
      function (event) {
        vm.touchstartX = event.changedTouches[0].screenX;
        vm.touchstartY = event.changedTouches[0].screenY;
      },
      false
    );

    touchableElement.addEventListener(
      "touchend",
      function (event) {
        vm.touchendX = event.changedTouches[0].screenX;
        vm.touchendY = event.changedTouches[0].screenY;
        vm.handleGesture();
      },
      false
    );
    this.fetchproviders();
  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {
    // handleProviderChange() {

    //   const selectedProvider = this.providers.find(
    //     (provider) => provider.id === this.providerId
    //   );
    //   console.log("Selected Provider ID:", this.providerId);
    //   console.log("Selected Provider Details:", selectedProvider);
    // },
    handleProviderChange() {
      if (!this.providerID) {
        return;
      }
      console.log("Selected Provider ID:", this.providerID);
      this.getCategories();
      if (this.categories.length > 0) {
        this.getCasinoGames(this.categories[0]);
      }
    },

    async fetchproviders() {
      const response = await casino.get("providers");
      console.log("providers", response);
      if (response.status == 200) {
        this.providers = response.data || [];
      }
    },

    handleGesture: function () {
      var scrollLeft = document.getElementById("catsec").scrollLeft;
      if (
        this.touchendX < this.touchstartX &&
        this.touchstartX - this.touchendX > 125
      ) {
        console.log("Swiped Left");
        console.log("start: " + this.touchstartX + "end: " + this.touchendX);
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0;
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index);
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          this.getCasinoGames(this.casinoCategoryActive);

          document.getElementById("catsec").scrollLeft = scrollLeft + 50;
        }
      }

      if (
        this.touchendX > this.touchstartX &&
        this.touchendX - this.touchstartX > 125
      ) {
        console.log("Swiped Right");

        console.log("start: " + this.touchstartX + "end: " + this.touchendX);
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if (index - 1 > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[index - 1];
          this.getCasinoGames(this.casinoCategoryActive);
          document.getElementById("catsec").scrollLeft = scrollLeft - 50;
        }
      }

      if (this.touchendY < this.touchstartY) {
        console.log("Swiped Up");
      }

      if (this.touchendY > this.touchstartY) {
        console.log("Swiped Down");
      }

      if (this.touchendY === this.touchstartY) {
        console.log("Tap");
      }
    },
    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return " active";
      } else {
        return "";
      }
    },
    closeModal: function () {
      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add("d-none");
    },

    performSearch: function () {
      var keyword = this.to_search;
      var games = this.allgames;

      console.log(keyword);

      var results = games.filter(function (el) {
        console.log(el.game_name);
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null
          ? false
          : game_name.match(keyword.toLowerCase()).length > 0;
      });

      console.log(
        "search results " + JSON.stringify(this.searchgamesdata, undefined, 2)
      );

      if (results.length > 0 && keyword !== "") {
        this.searchgamesdata = results;
      } else {
        this.searchgamesdata = [];
      }
    },

    keyUpSearch: function () {
      if (this.to_search == "") {
        this.searchgamesdata = [];
      }
    },

    loadPage: function (launch_url) {
      window.open(launch_url, "_blank");
    },

    getImg: function (img) {
      return img.replaceAll("/rec/325/", "/square/200/");
    },

    showSlides: function () {
      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {
        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      if (slides[slideIndex - 1]) {
        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";
      }

      setTimeout(function () {
        vm.showSlides();
      }, 2000); // Change image every 2 seconds
    },

    getLaunchURL: function (launch_url) {
      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function (categoryID) {
      if (!this.providerID) {
        console.error("Provider ID is missing!");
        alert("Please select a valid provider.ghfugtftgcfycyfdxydcyrxr");
        return;
      }

      this.casinoCategoryActive = categoryID;
      console.log(
        "Fetching games for Category:",
        categoryID.name,
        "Provider ID:",
        this.providerID
      );
      this.loading = true;

      var vm = this;

      let dat = {
        provider_id: parseInt(this.providerID, 10),
        // provider_id: this.providerID.toString(),
        category_id: categoryID.id,
        page: this.currentPage,
        per_page: this.perPage,
      };

      console.log("provider data passed", dat);

      //     const categoryID = this.casinoCategoryActive.id; // Current category
      // const page = this.currentPage;
      // const perPage = this.perPage;
      // const path = `/games/${providerID}/${categoryID}?page=${page}&per_page=${perPage}`;
      // console.log("Dynamic URL:", path);
      var path = "/games";

      casino.get(path, { params: dat }).then((resp) => {
        console.log("response", resp);
        vm.allgamesdata = resp.data.data;
        vm.allgames = vm.allgamesdata;

        console.log(
          "allgames --> " + JSON.stringify(vm.allgames, undefined, 2)
        );

        this.loading = false;

        window.scrollTo(0, 0);
      });
    },

    getCategories: function () {
      var vm = this;

      var path = "/category";

      let dat = {
        provider_id: this.providerID,
      };

      casino
        .get(path, { params: dat })
        .then((res) => {
          vm.categories = res.data;

          if (vm.categories.length > 0) {
            vm.setCategory(vm.categories[0]);
          }

          /*
          vm.jQuery.each(vm.games, function (k, v) {
            var key = v.Category.replaceAll(/ /g, "");
            var exists = false;
      
            // lets check if category exists
            vm.jQuery.each(categories, function (i, x) {
      
              if (x.key == key) {
      
                x.games.push(v);
                exists = true;
              }
      
              categories[i] = x;
            });
      
            if (!exists) {
      
              var gm = [];
              gm.push(v);
      
              var cat = {
                name: v.Category,
                key: key,
                games: gm,
              };
      
              categories.push(cat);
            }
          });
          */
        })
        .catch((err) => {
          if (err.response) {
            vm.setError("Failed", err.response.data.error_message);
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },

    setCategory: function (category) {
      this.category = category;
      this.getCasinoGames(category.id, this.providerID);
    },

    // getPopular: function () {

    //   var vm = this;

    //   var path = process.env.VUE_APP_URL_POPULAR_GAME;

    //   casino.get(path)
    //     .then(res => {
    //       this.homePagePopularGames = this.homePagePopularGames.concat(res.data.data);
    //     })
    //     .catch(err => {

    //       if (err.response) {

    //         vm.setError("Failed", err.response.data.error_message)

    //       } else if (err.request) {

    //         console.log(JSON.stringify(err.request));

    //       } else {

    //         console.log(JSON.stringify(err));

    //       }
    //     })
    // },
  },
  filters: {
    amount: function (val) {
      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>
